/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

const RowWithOther = ({ row, other }: { row?: any; other: any }): JSX.Element => (
    <>
        <div className="font-semibold text-blue">{row}</div>
        {other}
    </>
);

export default RowWithOther;
