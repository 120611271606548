import AppointmentsApi from './domains/AppointmentsApi';
import AuthApi from './domains/AuthApi';
import ClientsApi from './domains/ClientsApi';

export default class Api {
    public static auth(token: string): AuthApi {
        return new AuthApi(token);
    }

    public static appointments(token: string): AppointmentsApi {
        return new AppointmentsApi(token);
    }

    public static clients(token: string): ClientsApi {
        return new ClientsApi(token);
    }
}
