import {
    Button,
    ButtonType,
    Form,
    FormControl,
    FormProvider,
    Input,
    InputType,
    Modal,
    ModalHandler,
    Select,
    useSemantics,
} from '@ollico/optiiva-ui-sdk';
import { FormProps } from '@ollico/optiiva-ui-sdk/lib/components/FormProvider';
import { FormikHelpers } from 'formik';
import React, { useState } from 'react';
import shortid from 'shortid';

export interface SearchFilterData {
    uid: string | null;
    name: string | null;
    status?: string | null;
}

interface SearchFilterModalProps {
    modalHandler: ModalHandler;
    data: SearchFilterData;
    onSubmit: (data: SearchFilterData) => void;
}

const SearchFilterModal = (props: SearchFilterModalProps): JSX.Element => {
    const { modalHandler, data, onSubmit } = props;
    const { trans } = useSemantics();
    const formId = `form-${shortid()}`;
    const [filters, setFilters] = useState<SearchFilterData>({
        uid: null,
        name: null,
        status: null,
    });
    const handleSubmit = (values: SearchFilterData, helpers: FormikHelpers<SearchFilterData>) => {
        modalHandler.closeModal();
        helpers.setSubmitting(false);
        onSubmit(values);
    };

    return (
        <FormProvider<SearchFilterData> initialValues={data} onSubmit={handleSubmit}>
            {(form: FormProps<SearchFilterData>) => (
                <Form id={formId}>
                    <Modal
                        heading={trans('optiiva::titles.filter-tasks')}
                        open={modalHandler.isModalOpen}
                        onClose={modalHandler.closeModal}
                        footer={
                            <div className="flex justify-end">
                                <Button
                                    type={ButtonType.submit}
                                    form={formId}
                                    processing={form.isSubmitting}
                                >
                                    {trans('optiiva::labels.filter')}
                                </Button>
                            </div>
                        }
                    >
                        <div className="flex flex-col h-full">
                            <FormControl label={trans('optiiva::labels.uid')}>
                                <Input type={InputType.text} name="uid" />
                            </FormControl>

                            <FormControl label={trans('optiiva::labels.name')}>
                                <Input type={InputType.text} name="name" />
                            </FormControl>

                            {Object.keys(data).includes('status') && (
                                <>
                                    <FormControl label={trans('optiiva::labels.status')}>
                                        <Select
                                            name="status"
                                            options={{
                                                awaiting: trans(
                                                    'optiiva::enum.booking-status.awaiting',
                                                ),
                                                open: trans('optiiva::enum.booking-status.open'),
                                                closed: trans(
                                                    'optiiva::enum.booking-status.closed',
                                                ),
                                                complete: trans(
                                                    'optiiva::enum.booking-status.complete',
                                                ),
                                                dna: trans('optiiva::enum.booking-status.dna'),
                                            }}
                                        />
                                    </FormControl>
                                </>
                            )}
                        </div>
                    </Modal>
                </Form>
            )}
        </FormProvider>
    );
};

export default SearchFilterModal;
