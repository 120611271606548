import { DataRow, useSemantics } from '@ollico/optiiva-ui-sdk';
import SectionHeading from '@ollico/optiiva-ui-sdk/lib/components/SectionHeading';
import React from 'react';
import { SLHHClientExtended } from '../../../types/resources/VisitDataResource.interface';

const ClosingsData = ({ data }: { data: Pick<SLHHClientExtended, 'closings'> }): JSX.Element => {
    const { trans } = useSemantics();

    return (
        <>
            <SectionHeading
                className="pb-8"
                heading={trans('project:slhh::headings.closings')}
                subHeading={trans('project:slhh::headings.project')}
            />

            <DataRow
                squash
                label={trans('project:slhh::labels.recommend-home-visit')}
                value={data.closings.recommend_home_visit || trans('optiiva::misc.empty-line')}
            />

            <DataRow
                squash
                label={trans('project:slhh::labels.recommend-details')}
                value={data.closings.recommend_details || trans('optiiva::misc.empty-line')}
            />

            <DataRow
                squash
                label={trans('project:slhh::labels.energy-advisor-notes')}
                value={data.closings.energy_advisor_notes || trans('optiiva::misc.empty-line')}
            />
        </>
    );
};

export default ClosingsData;
