import { UserResource } from '../../types/resources';
import BaseApi from '../BaseApi';

enum AuthApiPath {
    VERIFY = 'app/verify',
    SEMANTICS = 'app/semantics',
    ME = 'me',
}

type Messages = {
    [key: string]: {
        [name: string]: string;
    };
};

export default class AuthApi extends BaseApi {
    public me(): Promise<UserResource> {
        return this.http.get(AuthApiPath.ME).then((res) => res.data);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public semantics(): Promise<Messages> {
        return this.http.post(AuthApiPath.SEMANTICS).then((res) => JSON.parse(res.data));
    }

    public verifySession(token: string): Promise<{ token: string; user: UserResource }> {
        return this.http.post(AuthApiPath.VERIFY, { token }).then((res) => res.data);
    }
}
