import {
    BookingDetailsModal,
    BookingStatusChip,
    CancelBookingModal,
    ClientProfileModal,
    Dialog,
    Dropdown,
    DropdownItem,
    DropdownType,
    generateColumn,
    SpinnerInverted,
    Table,
    TableBody,
    TableColumn,
    TableHead,
    TableRow,
    useFlashMessages,
    useModal,
    useSemantics,
} from '@ollico/optiiva-ui-sdk';
import { CancelBookingData } from '@ollico/optiiva-ui-sdk/lib/components/Bookings/CancelBookingModal';
import { FormHelpers } from '@ollico/optiiva-ui-sdk/lib/components/FormProvider';
import SLHHClient from '@ollico/optiiva-ui-sdk/lib/components/SLHH/types/SLHHClient.interface';
import { TabPanel } from '@ollico/optiiva-ui-sdk/lib/components/Tabs';
import { BookingStatus } from '@ollico/optiiva-ui-sdk/lib/types/enums';
import { BookingResource, Client } from '@ollico/optiiva-ui-sdk/lib/types/resources';
import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import VisitDataResource from 'src/types/resources/VisitDataResource.interface';
import Api from '../../api';
import { useSessionContext } from '../../context/auth';
import ApiErrorResponse from '../../types/ApiErrorResponse.interface';
import VisitModal from '../clients/VisitModal';

interface AppointmentsTableProps {
    appointments: BookingResource[];
    isPast: boolean;
    refreshPage: () => void;
}

const AppointmentsTable = (props: AppointmentsTableProps): JSX.Element => {
    const history = useHistory();
    const { trans } = useSemantics();
    const { context } = useSessionContext();
    const { flashSuccess } = useFlashMessages();
    const [processing, setProcessing] = useState<boolean>(false);
    const [showDialog, setShowDialog] = useState<BookingResource | null>(null);
    const [cancelBooking, setCancelBooking] = useState<BookingResource | null>(null);
    const [modalAppointment, setModalAppointment] = useState<BookingResource | null>(null);
    const [profileClient, setProfileClient] = useState<Client<SLHHClient>>();
    const [isLoadingTabs, setIsLoadingTabs] = useState(true);
    const [visitData, setVisitData] = useState<VisitDataResource>();
    const [appointmentData, setAppointmentData] = useState<VisitDataResource[]>([]);
    const modalHandler = useModal();
    const cancelModalHandler = useModal();
    const visitModalHandler = useModal();
    const { appointments, isPast, refreshPage } = props;

    const cols = useMemo(
        () => [
            generateColumn(trans('optiiva::labels.name'), 'name'),
            generateColumn(trans('optiiva::labels.date'), 'date'),
            generateColumn(trans('optiiva::labels.status'), 'status'),
            generateColumn('', 'actions', 'text-right'),
        ],
        [],
    );

    const openAppointment = (appointment: BookingResource) => {
        setShowDialog(appointment);
    };

    const cancelAppointment = (appointment: BookingResource) => {
        setCancelBooking(appointment);
        cancelModalHandler.openModal();
    };

    const onTabOpen = () => {
        if (!profileClient) return;

        setIsLoadingTabs(true);
        setAppointmentData([]);

        Api.appointments(context.token as string)
            .allVisits(profileClient.id as string)
            .then((data) => setAppointmentData(data))
            .finally(() => setIsLoadingTabs(false));
    };

    const openVisitModal = (appointment: VisitDataResource) => {
        setVisitData(appointment);
        visitModalHandler.openModal();
    };

    const cancelAppointmentRequest = (
        data: CancelBookingData,
        helpers: FormHelpers<CancelBookingData>,
    ): Promise<void> | void => {
        if (!cancelBooking) return;

        return new Promise((resolve, reject) => {
            setProcessing(true);

            Api.appointments(context.token as string)
                .cancel(cancelBooking.id, data)
                .then(() => {
                    flashSuccess(trans('optiiva::messages.bookings.cancelled'));
                    resolve();
                    setCancelBooking(null);
                    refreshPage();
                })
                .catch((error: ApiErrorResponse) => {
                    helpers.setErrors(error.response.data.errors);
                    reject();
                })
                .finally(() => {
                    setProcessing(false);
                });
        });
    };

    const getAppointmentMenu = (appointment: BookingResource): DropdownItem[] => {
        const items = [];

        if (appointment.status === BookingStatus.AWAITING && !isPast) {
            items.push({
                label: trans('optiiva::labels.open'),
                onClick: () => openAppointment(appointment),
            });

            items.push({
                label: trans('optiiva::labels.cancel'),
                onClick: () => cancelAppointment(appointment),
            });
        }

        if (appointment.status === BookingStatus.COMPLETE) {
            items.push({
                label: trans('optiiva::labels.edit'),
                onClick: () => {
                    history.push(`/appointments/edit/${appointment.id}`);
                },
            });
        }

        items.push({
            divideBefore: items.length > 0,
            label: trans('optiiva::labels.details'),
            onClick: () => {
                modalHandler.openModal();
                setModalAppointment(appointment);
            },
        });

        items.push({
            label: trans('optiiva::labels.view-client'),
            onClick: () => {
                setProfileClient(appointment.client as Client<SLHHClient>);
                modalHandler.openModal();
            },
        });

        items.push({
            label: trans('optiiva::labels.edit-client'),
            onClick: () => history.push(`clients/${appointment.client.id}/edit`),
        });

        return items;
    };

    return (
        <>
            <Table>
                <TableHead cols={cols} />
                <TableBody>
                    {appointments.map((appointment) => (
                        <TableRow key={appointment.id}>
                            <TableColumn>
                                {appointment.client.name}
                                <div className="font-semibold text-sm text-gray">
                                    {appointment.client.uid}
                                </div>
                            </TableColumn>
                            <TableColumn>{appointment.date_time_readable}</TableColumn>
                            <TableColumn>
                                <BookingStatusChip
                                    status={appointment.status}
                                    label={appointment.status_readable}
                                />
                            </TableColumn>
                            <TableColumn className="text-right">
                                <Dropdown
                                    type={DropdownType.MORE}
                                    position="right"
                                    items={getAppointmentMenu(appointment)}
                                />
                            </TableColumn>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            {showDialog && (
                <Dialog
                    show={!!showDialog}
                    text={
                        <>
                            <p>
                                Are you sure you want to open this appointment? You may only
                                continue if you answer {'"yes"'} to the following questions.
                            </p>
                            <ul className="list-inside list-disc">
                                <li>Have you completed your lone worker Health and Safety?</li>
                                <li>Have you completed a risk assessment for the visit?</li>
                                <li>Do you feel confident and safe to proceed?</li>
                            </ul>
                        </>
                    }
                    heading={trans('project:slhh::labels.open-appointment')}
                    onConfirm={() => {
                        history.push(
                            `/appointments/${showDialog.client.id}/intervention/${showDialog.id}`,
                        );
                    }}
                    onCancel={() => setShowDialog(null)}
                />
            )}

            {cancelBooking && (
                <CancelBookingModal
                    processing={processing}
                    showRebookOption
                    errors={{}}
                    modalHandler={cancelModalHandler}
                    booking={cancelBooking}
                    onSubmit={cancelAppointmentRequest}
                />
            )}

            {modalAppointment && (
                <BookingDetailsModal modalHandler={modalHandler} booking={modalAppointment} />
            )}

            {profileClient && (
                <>
                    <ClientProfileModal
                        onEditClick={() => history.push(`clients/${profileClient.id}/edit`)}
                        processing={false}
                        modalHandler={modalHandler}
                        onDestroyed={() => setIsLoadingTabs(true)}
                        client={profileClient}
                        onOpen={onTabOpen}
                        tabs={[{ label: 'Visits', identifier: 'appointments' }]}
                        appendTabs
                    >
                        <>
                            <TabPanel identifier="appointments">
                                {isLoadingTabs && (
                                    <div className="text-center my-6">
                                        <SpinnerInverted />
                                    </div>
                                )}

                                {appointmentData ? (
                                    appointmentData.map((appointment) => (
                                        <div
                                            onClick={() => openVisitModal(appointment)}
                                            className="border-2 border-gray-light p-4 rounded-md mb-4 flex justify-between items-center hover:border-blue cursor-pointer"
                                            key={`c-${appointment.booking.id}`}
                                        >
                                            <div className="font-semibold leading-none">
                                                {appointment.booking.date_time_readable}
                                            </div>
                                            <BookingStatusChip
                                                status={appointment.booking.status}
                                                label={appointment.booking.status_readable}
                                            />
                                        </div>
                                    ))
                                ) : (
                                    <span />
                                )}
                            </TabPanel>
                        </>
                    </ClientProfileModal>

                    {visitData && <VisitModal modalHandler={visitModalHandler} data={visitData} />}
                </>
            )}
        </>
    );
};

export default AppointmentsTable;
