import { DataRow, useSemantics } from '@ollico/optiiva-ui-sdk';
import SectionHeading from '@ollico/optiiva-ui-sdk/lib/components/SectionHeading';
import React from 'react';
import { SLHHClientExtended } from '../../../types/resources/VisitDataResource.interface';

const FeedbackData = ({ data }: { data: Pick<SLHHClientExtended, 'feedback'> }): JSX.Element => {
    const { trans } = useSemantics();

    return (
        <>
            <SectionHeading
                className="pb-8"
                heading={trans('project:slhh::headings.feedback')}
                subHeading={trans('project:slhh::headings.project')}
            />
            <DataRow
                squash
                label={trans('project:slhh::labels.advice-quality')}
                value={data.feedback.advice_quality_readable || trans('optiiva::misc.empty-line')}
            />
            <DataRow
                squash
                label={trans('project:slhh::labels.managing-energy')}
                value={data.feedback.managing_energy_readable || trans('optiiva::misc.empty-line')}
            />
            <DataRow
                squash
                label={trans('project:slhh::labels.able-to-stay-warm')}
                value={
                    data.feedback.able_to_stay_warm_readable || trans('optiiva::misc.empty-line')
                }
            />
            <DataRow
                squash
                label={trans('project:slhh::labels.most-useful-learned')}
                value={data.feedback.most_useful_learned || trans('optiiva::misc.empty-line')}
            />
            <DataRow
                squash
                label={trans('project:slhh::labels.can-be-done-better')}
                value={data.feedback.can_be_done_better || trans('optiiva::misc.empty-line')}
            />
        </>
    );
};

export default FeedbackData;
