import {
    BookingStatusChip,
    ClientProfileModal,
    Dropdown,
    DropdownItem,
    DropdownType,
    generateColumn,
    SpinnerInverted,
    Table,
    TableBody,
    TableColumn,
    TableHead,
    TableRow,
    useModal,
    useSemantics,
} from '@ollico/optiiva-ui-sdk';
import SLHHClient from '@ollico/optiiva-ui-sdk/lib/components/SLHH/types/SLHHClient.interface';
import { TabPanel } from '@ollico/optiiva-ui-sdk/lib/components/Tabs';
import { Client } from '@ollico/optiiva-ui-sdk/lib/types/resources';
import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Api from 'src/api';
import VisitModal from 'src/components/clients/VisitModal';
import { useSessionContext } from 'src/context/auth';
import VisitDataResource from 'src/types/resources/VisitDataResource.interface';

interface ClientsTableProps {
    clients: Client<SLHHClient>[];
}

const ClientsTable = (props: ClientsTableProps): JSX.Element => {
    const { trans } = useSemantics();
    const history = useHistory();
    const { context } = useSessionContext();
    const [profileClient, setProfileClient] = useState<Client<SLHHClient>>();
    const [isLoadingTabs, setIsLoadingTabs] = useState(true);
    const [visitData, setVisitData] = useState<VisitDataResource>();
    const [appointmentData, setAppointmentData] = useState<VisitDataResource[]>([]);
    const modalHandler = useModal();
    const visitModalHandler = useModal();
    const { clients } = props;

    const cols = useMemo(
        () => [
            generateColumn(trans('optiiva::labels.client'), 'client'),
            generateColumn(trans('optiiva::labels.phone-number'), 'phone_number'),
            generateColumn(trans('optiiva::labels.postcode'), 'postcode'),
            generateColumn('', 'actions', 'text-right'),
        ],
        [],
    );

    const getContextMenu = (client: Client<SLHHClient>): DropdownItem[] => [
        {
            label: trans('optiiva::labels.edit-client'),
            onClick: () => history.push(`clients/${client.id}/edit`),
        },
        {
            label: trans('optiiva::labels.view-client'),
            onClick: () => {
                setProfileClient(client);
                modalHandler.openModal();
            },
        },
    ];

    const openVisitModal = (appointment: VisitDataResource) => {
        setVisitData(appointment);
        visitModalHandler.openModal();
    };

    const onTabOpen = () => {
        if (!profileClient) return;

        setIsLoadingTabs(true);
        setAppointmentData([]);

        Api.appointments(context.token as string)
            .allVisits(profileClient.id as string)
            .then((data) => setAppointmentData(data))
            .finally(() => setIsLoadingTabs(false));
    };

    return (
        <>
            <Table>
                <TableHead cols={cols} />
                <TableBody>
                    {clients.map((client) => (
                        <TableRow key={client.id}>
                            <TableColumn>
                                {client.name}
                                <div className="font-semibold text-sm text-gray">{client.uid}</div>
                            </TableColumn>
                            <TableColumn>
                                {client.phone_number ||
                                    client.secondary_phone_number ||
                                    trans('optiiva::misc.empty-line')}
                            </TableColumn>
                            <TableColumn>
                                {client.postcode || trans('optiiva::misc.empty-line')}
                            </TableColumn>
                            <TableColumn className="text-right">
                                <Dropdown
                                    type={DropdownType.MORE}
                                    position="right"
                                    items={getContextMenu(client)}
                                />
                            </TableColumn>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {profileClient && (
                <>
                    <ClientProfileModal
                        onEditClick={() => history.push(`clients/${profileClient.id}/edit`)}
                        processing={false}
                        modalHandler={modalHandler}
                        onDestroyed={() => setIsLoadingTabs(true)}
                        client={profileClient}
                        onOpen={onTabOpen}
                        tabs={[{ label: 'Visits', identifier: 'appointments' }]}
                        appendTabs
                    >
                        <>
                            <TabPanel identifier="appointments">
                                {isLoadingTabs && (
                                    <div className="text-center my-6">
                                        <SpinnerInverted />
                                    </div>
                                )}

                                {appointmentData ? (
                                    appointmentData.map((appointment) => (
                                        <div
                                            onClick={() => openVisitModal(appointment)}
                                            className="border-2 border-gray-light p-4 rounded-md mb-4 flex justify-between items-center hover:border-blue cursor-pointer"
                                            key={`c-${appointment.booking.id}`}
                                        >
                                            <div className="font-semibold leading-none">
                                                {appointment.booking.date_time_readable}
                                            </div>
                                            <BookingStatusChip
                                                status={appointment.booking.status}
                                                label={appointment.booking.status_readable}
                                            />
                                        </div>
                                    ))
                                ) : (
                                    <span />
                                )}
                            </TabPanel>
                        </>
                    </ClientProfileModal>

                    {visitData && <VisitModal modalHandler={visitModalHandler} data={visitData} />}
                </>
            )}
        </>
    );
};

export default ClientsTable;
