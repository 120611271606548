import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './sass/app.scss';
import Main from './Main';
import reportWebVitals from './reportWebVitals';
// import { makeServer } from './server';
import * as serviceWorker from './serviceWorker';
import { SessionProvider } from './context/auth';

// if (process.env.NODE_ENV === 'development') {
// makeServer();
// }

ReactDOM.render(
    <BrowserRouter>
        <SessionProvider>
            <Main />
        </SessionProvider>
    </BrowserRouter>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();
