import { DataRow, useSemantics } from '@ollico/optiiva-ui-sdk';
import SectionHeading from '@ollico/optiiva-ui-sdk/lib/components/SectionHeading';
import React from 'react';
import { SLHHClientExtended } from '../../../types/resources/VisitDataResource.interface';
import RowWithOther from './RowWithOther';

const WaterSureData = ({
    data,
}: {
    data: Pick<SLHHClientExtended, 'white_goods'>;
}): JSX.Element => {
    const { trans } = useSemantics();

    return (
        <>
            <SectionHeading
                className="pb-8"
                heading={trans('optiiva::headings.white-goods')}
                subHeading={trans('optiiva::headings.project')}
            />

            <DataRow
                squash
                label={trans('project:slhh::labels.applied-for')}
                value={
                    <RowWithOther
                        row={
                            data.white_goods.applied_for_readable
                                ? data.white_goods.applied_for_readable.join(', ')
                                : trans('optiiva::misc.empty-line')
                        }
                        other={data.white_goods.applied_for_other}
                    />
                }
            />
        </>
    );
};

export default WaterSureData;
