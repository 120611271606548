import { useFlashMessages, useSemantics } from '@ollico/optiiva-ui-sdk';
import { explodeFlatErrors, FormHelpers } from '@ollico/optiiva-ui-sdk/lib/components/FormProvider';
import { ClientForm } from '@ollico/optiiva-ui-sdk/lib/components/SLHH';
import SLHHClient from '@ollico/optiiva-ui-sdk/lib/components/SLHH/types/SLHHClient.interface';
import { ErrorStack } from '@ollico/optiiva-ui-sdk/lib/types/misc';
import { Client } from '@ollico/optiiva-ui-sdk/lib/types/resources';
import React, { useState } from 'react';
import Api from '../../api';
import { useSessionContext } from '../../context/auth';
import { BaseLayout } from '../../components/layout';
import { BooleanOption } from '@ollico/optiiva-ui-sdk/lib/types/enums';
import { useHistory } from 'react-router-dom';

const defaultClient = (): Client<SLHHClient> => ({
    id: null,
    uid: null,
    full_name: null,
    name: null,
    first_name: null,
    last_name: null,
    project: {} as SLHHClient,
});

const AddClient = (): JSX.Element => {
    const { trans } = useSemantics();
    const history = useHistory();
    const { context } = useSessionContext();
    const { flashSuccess, flashError } = useFlashMessages();
    const [client] = useState<Client<SLHHClient>>(defaultClient());

    const onSubmit = (data: Client<SLHHClient>, helpers: FormHelpers<Client<SLHHClient>>) => {
        return new Promise((resolve, reject) => {
            Api.clients(context.token as string)
                .create(data)
                .then(() => {
                    flashSuccess(trans('optiiva::messages.client.created'));
                    setTimeout(() => {
                        resolve(null);
                        helpers.resetForm();
                        history.push('/clients');
                    }, 250);
                })
                .catch((error: { response: { data: { message: string; errors: ErrorStack } } }) => {
                    flashError(error.response.data.message);
                    helpers.setErrors(explodeFlatErrors(error.response.data.errors));
                    reject(error);
                });
        });
    };

    return (
        <BaseLayout title={trans('optiiva::titles.add-client')}>
            <ClientForm
                isBget={context.user?.organisation?.is_bget === BooleanOption.YES}
                onlyProject
                hideInfo
                errors={{}}
                onSubmit={onSubmit}
                client={client}
            />
        </BaseLayout>
    );
};

export default AddClient;
