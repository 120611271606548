import { AuthLayout, Card } from '@ollico/optiiva-ui-sdk';
import React from 'react';

const PageNotFound = (): JSX.Element => (
    <AuthLayout>
        <Card heading="Page not found">
            <div>The page you were looking for could not be found.</div>
        </Card>
    </AuthLayout>
);

export default PageNotFound;
