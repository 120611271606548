import { DataRow, useSemantics } from '@ollico/optiiva-ui-sdk';
import SectionHeading from '@ollico/optiiva-ui-sdk/lib/components/SectionHeading';
import React from 'react';
import { SLHHClientExtended } from '../../../types/resources/VisitDataResource.interface';
import RowWithOther from './RowWithOther';

const HotWaterData = ({ data }: { data: Pick<SLHHClientExtended, 'hot_water'> }): JSX.Element => {
    const { trans } = useSemantics();

    return (
        <>
            <SectionHeading
                className="pb-8"
                heading={trans('project:slhh::headings.hot-water')}
                subHeading={trans('project:slhh::headings.details')}
            />

            <DataRow
                horizontal
                squash
                label={trans('project:slhh::labels.main-system')}
                value={
                    <RowWithOther
                        row={
                            data.hot_water.main_system_readable || trans('optiiva::misc.empty-line')
                        }
                        other={data.hot_water.main_system_no}
                    />
                }
            />

            <DataRow
                horizontal
                squash
                label={trans('project:slhh::labels.cylinder-present')}
                value={
                    data.hot_water.cylinder_present_readable || trans('optiiva::misc.empty-line')
                }
            />

            <DataRow
                horizontal
                squash
                label={trans('project:slhh::labels.cylinder-insulation')}
                value={
                    data.hot_water.cylinder_insulation_readable || trans('optiiva::misc.empty-line')
                }
            />

            <DataRow
                horizontal
                squash
                label={trans('project:slhh::labels.num-bedrooms')}
                value={data.hot_water.num_bedrooms || trans('optiiva::misc.empty-line')}
            />

            <DataRow
                horizontal
                squash
                label={trans('project:slhh::labels.energy-issues')}
                value={
                    <RowWithOther
                        row={
                            data.hot_water.energy_issues_readable ||
                            trans('optiiva::misc.empty-line')
                        }
                        other={data.hot_water.energy_issues_other}
                    />
                }
            />
        </>
    );
};

export default HotWaterData;
