import { EmptyState, useFlashMessages, useSemantics } from '@ollico/optiiva-ui-sdk';
import { explodeFlatErrors, FormHelpers } from '@ollico/optiiva-ui-sdk/lib/components/FormProvider';
import { ClientForm } from '@ollico/optiiva-ui-sdk/lib/components/SLHH';
import { default as SLHHClient } from '@ollico/optiiva-ui-sdk/lib/components/SLHH/types/SLHHClient.interface';
import { Client } from '@ollico/optiiva-ui-sdk/lib/types/resources';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import ApiErrorResponse from '../../types/ApiErrorResponse.interface';
import Api from '../../api';
import { BaseLayout } from '../../components/layout';
import Loader from '../../components/misc/Loader';
import { useSessionContext } from '../../context/auth';
import { BooleanOption } from '@ollico/optiiva-ui-sdk/lib/types/enums';

interface TParams {
    clientId: string;
    bookingId: string;
}

export const formatClient = (data: Client<SLHHClient>): Client<SLHHClient> => ({
    ...data,
    project: {
        ...(data.project || {}),
        home_visit: data.project?.home_visit || {},
        heating: data.project?.heating || {},
        hot_water: data.project?.hot_water || {},
        behavioural_advice: data.project?.behavioural_advice || {},
        warm_home: data.project?.warm_home || {},
        watersure: data.project?.watersure || {},
        white_goods: data.project?.white_goods || {},
        smart_meter: data.project?.smart_meter || {},
        onward_referral: data.project?.onward_referral || {},
        feedback: data.project?.feedback || {},
        closings: data.project?.closings || {},
        eco_flex: data.project?.eco_flex || {},
        bget: data.project?.bget || {},
        resident: data.project?.resident || {},
    },
});

const AppointmentIntervention = ({ match }: RouteComponentProps<TParams>): JSX.Element => {
    const history = useHistory();
    const { trans } = useSemantics();
    const { context } = useSessionContext();
    const { flashError, flashSuccess } = useFlashMessages();
    const [client, setClient] = useState<Client<SLHHClient>>();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        Api.clients(context.token as string)
            .show(match.params.clientId)
            .then((data) => setClient(formatClient(data)))
            .finally(() => setLoading(false));
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }, []);

    const onSubmit = (
        data: Client<SLHHClient>,
        helpers: FormHelpers<Client<SLHHClient>>,
    ): Promise<void> => {
        return new Promise((resolve, reject) => {
            Api.appointments(context.token as string)
                .create(match.params.clientId, match.params.bookingId, data)
                .then(() => {
                    flashSuccess(trans('optiiva::messages.appointment.saved'));
                    resolve();
                    setTimeout(() => history.goBack(), 250);
                })
                .catch((error: ApiErrorResponse) => {
                    flashError(error.response.data.message);
                    if (error.response.status === 422) {
                        helpers.setErrors(explodeFlatErrors(error.response.data.errors));
                    }
                    reject();
                });
        });
    };

    return (
        <BaseLayout title={trans('optiiva::titles.appointments')}>
            {loading ? (
                <Loader />
            ) : (
                <>
                    {client ? (
                        <ClientForm
                            isBget={context.user?.organisation?.is_bget === BooleanOption.YES}
                            errors={{}}
                            onSubmit={onSubmit}
                            client={client}
                        />
                    ) : (
                        <EmptyState message="The client requested could not be found." />
                    )}
                </>
            )}
        </BaseLayout>
    );
};

export default AppointmentIntervention;
