import { DataRow, useSemantics } from '@ollico/optiiva-ui-sdk';
import SectionHeading from '@ollico/optiiva-ui-sdk/lib/components/SectionHeading';
import React from 'react';
import { SLHHClientExtended } from '../../../types/resources/VisitDataResource.interface';
import RowWithOther from './RowWithOther';

const WaterSureData = ({ data }: { data: Pick<SLHHClientExtended, 'watersure'> }): JSX.Element => {
    const { trans } = useSemantics();

    return (
        <>
            <SectionHeading
                className="pb-8"
                heading={trans('project:slhh::headings.watersure')}
                subHeading={trans('project:slhh::headings.slhh')}
            />

            <DataRow
                squash
                label={trans('project:slhh::labels.water-advice-given')}
                value={
                    <RowWithOther
                        row={
                            data.watersure.water_advice_readable
                                ? data.watersure.water_advice_readable.join(', ')
                                : trans('optiiva::misc.empty-line')
                        }
                        other={data.watersure.water_advice_other}
                    />
                }
            />

            <DataRow
                squash
                label={trans('project:slhh::labels.water-advice-taken')}
                value={
                    <RowWithOther
                        row={
                            data.watersure.water_action_readable
                                ? data.watersure.water_action_readable.join(', ')
                                : trans('optiiva::misc.empty-line')
                        }
                        other={data.watersure.water_action_other}
                    />
                }
            />

            <DataRow
                squash
                label={trans('project:slhh::labels.fuel-debt')}
                value={data.watersure.fuel_debt || trans('optiiva::misc.empty-line')}
            />

            <DataRow
                squash
                label={trans('project:slhh::labels.water-debt')}
                value={data.watersure.water_debt || trans('optiiva::misc.empty-line')}
            />

            <DataRow
                squash
                label={trans('project:slhh::labels.cause-of-debt')}
                value={data.watersure.cause_of_debt || trans('optiiva::misc.empty-line')}
            />

            <DataRow
                squash
                label={trans('project:slhh::labels.debt-advice-given')}
                value={data.watersure.advice_given_readable || trans('optiiva::misc.empty-line')}
            />
        </>
    );
};

export default WaterSureData;
