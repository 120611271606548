import { DataRow, useSemantics } from '@ollico/optiiva-ui-sdk';
import SectionHeading from '@ollico/optiiva-ui-sdk/lib/components/SectionHeading';
import React from 'react';
import { SLHHClientExtended } from '../../../types/resources/VisitDataResource.interface';
import RowWithOther from './RowWithOther';

const WarmHomeData = ({ data }: { data: Pick<SLHHClientExtended, 'warm_home'> }): JSX.Element => {
    const { trans } = useSemantics();

    return (
        <>
            <SectionHeading
                className="pb-8"
                heading={trans('project:slhh::headings.warm-home-discounts')}
                subHeading={trans('project:slhh::headings.slhh')}
            />

            <DataRow
                squash
                label={trans('project:slhh::labels.warm-home-advice')}
                value={
                    <RowWithOther
                        row={
                            data.warm_home.warm_home_advice_readable ||
                            trans('optiiva::misc.empty-line')
                        }
                        other={data.warm_home.warm_home_advice_other}
                    />
                }
            />

            <DataRow
                squash
                label={trans('project:slhh::labels.warm-home-action')}
                value={
                    <RowWithOther
                        row={
                            data.warm_home.warm_home_action_readable ||
                            trans('optiiva::misc.empty-line')
                        }
                        other={data.warm_home.warm_home_action_other}
                    />
                }
            />

            <DataRow
                squash
                label={trans('project:slhh::labels.psr-advice')}
                value={
                    <RowWithOther
                        row={
                            data.warm_home.psr_advice_readable || trans('optiiva::misc.empty-line')
                        }
                        other={data.warm_home.psr_advice_other}
                    />
                }
            />

            <DataRow
                squash
                label={trans('project:slhh::labels.psr-action')}
                value={data.warm_home.psr_action_readable || trans('optiiva::misc.empty-line')}
            />
        </>
    );
};

export default WarmHomeData;
