import { DataRow, useSemantics } from '@ollico/optiiva-ui-sdk';
import SectionHeading from '@ollico/optiiva-ui-sdk/lib/components/SectionHeading';
import React from 'react';
import { SLHHClientExtended } from '../../../types/resources/VisitDataResource.interface';
import RowWithOther from './RowWithOther';

const OnwardReferralData = ({
    data,
}: {
    data: Pick<SLHHClientExtended, 'onward_referral'>;
}): JSX.Element => {
    const { trans } = useSemantics();

    return (
        <>
            <SectionHeading
                className="pb-8"
                heading={trans('project:slhh::headings.onward-referral')}
                subHeading={trans('project:slhh::headings.project')}
            />

            <DataRow
                squash
                label={trans('project:slhh::labels.referrer-signpost-to')}
                value={
                    data.onward_referral.referrer_signpost_to || trans('optiiva::misc.empty-line')
                }
            />

            <DataRow
                squash
                label={trans('project:slhh::labels.client-followup-call')}
                value={
                    <RowWithOther
                        row={
                            data.onward_referral.client_followup_call_readable ||
                            trans('optiiva::misc.empty-line')
                        }
                        other={
                            <>
                                <span className="block">
                                    {data.onward_referral.client_followup_number}
                                </span>
                                <span className="block">
                                    {data.onward_referral.client_followup_call_date}
                                </span>
                            </>
                        }
                    />
                }
            />

            <DataRow
                squash
                label={trans('project:slhh::labels.energy-efficiency-install-consent')}
                value={
                    <RowWithOther
                        row={
                            data.onward_referral.energy_efficiency_install_consent_readable ||
                            trans('optiiva::misc.empty-line')
                        }
                        other={data.onward_referral.energy_efficiency_install_consent_why}
                    />
                }
            />

            <DataRow
                squash
                label={trans('project:slhh::labels.energy-efficiency-install-consent')}
                value={
                    data.onward_referral.energy_efficiency_measure_readable
                        ? data.onward_referral.energy_efficiency_measure_readable.join(', ')
                        : trans('optiiva::misc.empty-line')
                }
            />

            <DataRow
                squash
                label={trans('project:slhh::labels.energy-efficiency-measure-why')}
                value={
                    data.onward_referral.energy_efficiency_measure_why ||
                    trans('optiiva::misc.empty-line')
                }
            />
        </>
    );
};

export default OnwardReferralData;
