import React from 'react';
import { SpinnerInverted } from '@ollico/optiiva-ui-sdk';

const Loader = (): JSX.Element => (
    <div className="text-center">
        <SpinnerInverted />
    </div>
);

export default Loader;
