import axios, { AxiosInstance } from 'axios';

export default abstract class BaseApi {
    protected readonly http: AxiosInstance;

    constructor(token: string) {
        this.http = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: 30000,
            headers: {
                Authorization: token.length > 0 ? `Bearer ${token}` : null,
                'Content-Type': 'application/json',
                'X-Optiiva-Project': 'in-scope',
                'X-Optiiva-Project-Id': 'a176940c-a732-4e29-beb8-5b9442ed13f4',
            },
        });

        this.http.interceptors.response.use(
            (response) => response,
            (error) => {
                if (error.response.status === 401) {
                    window.location.href = '/auth/login';
                    return;
                }

                return Promise.reject(error);
            },
        );
    }
}
