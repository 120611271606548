import { CancelBookingData } from '@ollico/optiiva-ui-sdk/lib/components/Bookings/CancelBookingModal';
import SLHHClient from '@ollico/optiiva-ui-sdk/lib/components/SLHH/types/SLHHClient.interface';
import {
    BookingResource,
    Client,
    PaginatedResource,
} from '@ollico/optiiva-ui-sdk/lib/types/resources';
import VisitDataResource from 'src/types/resources/VisitDataResource.interface';
import BaseApi from '../BaseApi';

enum AppointmentsApiPath {
    PAGINATE = 'project/slhh/appointments',
    CANCEL = 'project/slhh/appointments',
    TODAYS = 'project/slhh/appointments/today',
    REST_OF_WEEK = 'project/slhh/appointments/rest-of-week',
    STATS = 'project/slhh/stats',
    PREFIX = 'project/slhh',
}

export default class AppointmentsApi extends BaseApi {
    public todays(): Promise<BookingResource[]> {
        return this.http.get(AppointmentsApiPath.TODAYS).then((res) => res.data);
    }

    public restOfWeek(): Promise<BookingResource[]> {
        return this.http.get(AppointmentsApiPath.REST_OF_WEEK).then((res) => res.data);
    }

    public cancel(id: string, data: CancelBookingData): Promise<void> {
        return this.http.put(`${AppointmentsApiPath.CANCEL}/${id}/cancel`, data);
    }

    public allVisits(id: string): Promise<VisitDataResource[]> {
        return this.http
            .get(`${AppointmentsApiPath.PREFIX}/clients/${id}/bookings`)
            .then((res) => res.data);
    }

    public visit(id: string): Promise<{ booking: BookingResource; visit: Client<SLHHClient> }> {
        return this.http
            .get(`${AppointmentsApiPath.PREFIX}/appointment/completed/${id}`)
            .then((res) => res.data);
    }

    public stats(): Promise<{ [key: string]: number }> {
        return this.http.get(AppointmentsApiPath.STATS).then((res) => res.data);
    }

    public create(clientId: string, bookingId: string, data: Client<SLHHClient>): Promise<boolean> {
        return this.http
            .post(
                `${AppointmentsApiPath.PREFIX}/appointments/${clientId}/intervention/${bookingId}`,
                data,
            )
            .then(() => true);
    }

    public update(bookingId: string, data: Client<SLHHClient>): Promise<boolean> {
        return this.http.put(
            `${AppointmentsApiPath.PREFIX}/appointment/completed/${bookingId}/edit`,
            data,
        );
    }

    public paginate(
        page: number,
        filters: { [key: string]: string },
    ): Promise<PaginatedResource<BookingResource>> {
        return this.http
            .get(AppointmentsApiPath.PAGINATE, {
                params: { page, per_page: 20, ...filters },
            })
            .then((res) => res.data);
    }
}
