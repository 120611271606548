import { AuthLayout, Card, SpinnerInverted } from '@ollico/optiiva-ui-sdk';
import React, { useEffect, useState } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import Api from '../../api/Api';
import { useSessionContext } from '../../context/auth';

interface TParams {
    token: string;
}

enum AuthStatus {
    FAILED = 'failed',
    SUCCESS = 'success',
}

const AuthCallbackHandler = ({ match }: RouteComponentProps<TParams>): JSX.Element => {
    const [processing, setProcessing] = useState(true);
    const [status, setStatus] = useState<AuthStatus>(AuthStatus.SUCCESS);
    const { authorizeSession } = useSessionContext();

    useEffect(() => {
        setTimeout(() => {
            Api.auth('')
                .verifySession(match.params.token)
                .then((data) => authorizeSession(data.user, data.token))
                .catch(() => setStatus(AuthStatus.FAILED))
                .finally(() => setProcessing(false));
        }, 1000);
    }, []);

    return (
        <AuthLayout>
            <Card>
                {processing ? (
                    <div className="flex items-center">
                        <div>
                            <SpinnerInverted />
                        </div>
                        <span className="pl-4 font-medium text-sm leading-none">
                            Verifying session
                        </span>
                    </div>
                ) : (
                    <>
                        {status === AuthStatus.FAILED ? (
                            <strong>Authorisation failed</strong>
                        ) : (
                            <Redirect to="/" />
                        )}
                    </>
                )}
            </Card>
        </AuthLayout>
    );
};

export default AuthCallbackHandler;
