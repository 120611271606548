import { DataRow, useSemantics } from '@ollico/optiiva-ui-sdk';
import SectionHeading from '@ollico/optiiva-ui-sdk/lib/components/SectionHeading';
import React from 'react';
import { SLHHClientExtended } from '../../../types/resources/VisitDataResource.interface';
import RowWithOther from './RowWithOther';

const SmartMeterData = ({
    data,
}: {
    data: Pick<SLHHClientExtended, 'smart_meter'>;
}): JSX.Element => {
    const { trans } = useSemantics();

    return (
        <>
            <SectionHeading
                className="pb-8"
                heading={trans('project:slhh::headings.smart-meter')}
                subHeading={trans('project:slhh::headings.project')}
            />

            <DataRow
                squash
                label={trans('project:slhh::labels.has-smart-meter')}
                value={
                    <RowWithOther
                        row={
                            data.smart_meter.has_smart_meter_readable ||
                            trans('optiiva::misc.empty-line')
                        }
                        other={data.smart_meter.has_smart_meter_other}
                    />
                }
            />
        </>
    );
};

export default SmartMeterData;
