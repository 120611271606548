import { Messages } from 'lang.js';
import React, { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';
import Api from '../api/Api';
import store, { storeKeys } from '../store';
import { UserResource } from '../types/resources';

interface Session {
    user: [UserResource | null, Dispatch<SetStateAction<UserResource | null>>];
    isLoggedIn: [boolean, Dispatch<SetStateAction<boolean>>];
    token: [string | null, Dispatch<SetStateAction<string | null>>];
    appReady: [boolean, Dispatch<SetStateAction<boolean>>];
    lang: [Messages, Dispatch<SetStateAction<Messages>>];
}

const defaultSession = {
    isLoggedIn: [false, () => undefined],
    token: [null, () => undefined],
    user: [null, () => undefined],
    appReady: [false, () => undefined],
    lang: [{}, () => undefined],
} as Session;

const SessionContext = createContext<Session>(defaultSession);

export function SessionProvider({ children }: { children: React.ReactNode }): JSX.Element {
    const session = {
        isLoggedIn: useState<boolean>(false),
        token: useState<string | null>(null),
        user: useState<UserResource | null>(null),
        appReady: useState<boolean>(false),
        lang: useState<Messages>({}),
    };

    return <SessionContext.Provider value={session}>{children}</SessionContext.Provider>;
}

interface UseSession {
    context: {
        isLoggedIn: boolean;
        appReady: boolean;
        token: string | null;
        user: UserResource | null;
    };
    semantics: { lang: Messages };
    authorizeSession: (user: UserResource, token: string) => void;
    initializeApp: (skipAuthCheck: boolean) => void;
}

export function useSessionContext(): UseSession {
    const {
        isLoggedIn: [isLoggedIn, setIsLoggedIn],
        token: [token, setToken],
        user: [user, setUser],
        appReady: [appReady, setAppReady],
        lang: [lang, setLang],
    } = useContext(SessionContext);

    const authorizeSession = (user: UserResource, token: string) => {
        store.setItem(storeKeys.AUTH_TOKEN, token);
        store.setItem(storeKeys.USER, user);
        setToken(token);
        setUser(user);
        setIsLoggedIn(true);
    };

    const bootAuthorisation = (skipAuthCheck = false) => {
        // DRY
        const callback = () => setAppReady(true);

        if (skipAuthCheck) return callback();

        store.getItem<string>(storeKeys.AUTH_TOKEN, (err, token) => {
            // There is no active token, so just initialize
            if (!token || err) return callback();

            // Verify the session token by getting the user
            Api.auth(token)
                .me()
                .then((data) => authorizeSession(data, token))
                .catch(() => console.log('- No active session found'))
                .finally(() => callback());
        });
    };

    return {
        context: { isLoggedIn, appReady, user, token },
        authorizeSession,
        semantics: { lang },
        initializeApp: (skipAuthCheck = false) => {
            store.getItem<Messages>(storeKeys.SEMANTICS, (err, messages) => {
                if (messages) setLang(messages);

                Api.auth('')
                    .semantics()
                    .then((semantics) => {
                        setLang(semantics);
                        store.setItem(storeKeys.SEMANTICS, semantics, () => {
                            bootAuthorisation(skipAuthCheck);
                        });
                    })
                    .catch(() => {
                        console.log('Could not load semantics');
                        bootAuthorisation(skipAuthCheck);
                    });
            });
        },
    };
}
