import {
    Button,
    ButtonType,
    Card,
    CardSize,
    DangerButton,
    OptiivaSdk,
    PaginationLinks,
    route,
    useModal,
    useSemantics,
} from '@ollico/optiiva-ui-sdk';
import { Resources } from '@ollico/optiiva-ui-sdk/lib/types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Api from '../../api';
import ClientsTable from '../../components/clients/ClientsTable';
import Loader from '../../components/misc/Loader';
import { useSessionContext } from '../../context/auth';
import { BaseLayout } from '../../components/layout';
import { PaginatedResource } from '@ollico/optiiva-ui-sdk/lib/types/resources';
import { RouteComponentProps } from 'react-router-dom';
import QueryString from 'query-string';
import SLHHClient from '@ollico/optiiva-ui-sdk/lib/components/SLHH/types/SLHHClient.interface';
import { SelectableObject } from '@ollico/optiiva-ui-sdk/lib/types/misc';
import { SearchFilterModal } from 'src/components/Modal';
import { SearchFilterData } from 'src/components/Modal/SearchFilterModal';

interface ClientsProps {
    location: {
        search: string;
        pathname: string;
    };
}

const defaultFilters = {
    uid: null,
    name: null,
};

const Clients = ({ location }: ClientsProps): JSX.Element => {
    const [filters, setFilters] = useState<SearchFilterData>(defaultFilters);
    const filterModal = useModal();
    const { trans } = useSemantics();
    const params = QueryString.parse(location.search);
    const { context } = useSessionContext();
    const [clients, setClients] = useState<PaginatedResource<Resources.Client<SLHHClient>>>();
    const [processing, setProcessing] = useState(true);
    const [page, setPage] = useState(1);
    const clearFilters = useCallback(() => {
        setFilters(defaultFilters);
    }, []);

    const loadClients = () => {
        setProcessing(true);

        const filtered: { [key: string]: string } = {};
        const keys = Object.keys(filters);

        for (let index = 0; index < keys.length; index++) {
            const filter = filters[keys[index]];
            if (filter) {
                filtered[keys[index]] = filter;
            }
        }

        Api.clients(context.token as string)
            .paginate(page, {
                ...filtered,
                when: location.pathname === '/clients/past' ? 'past' : 'upcoming',
            })
            .then((data) => setClients(data))
            .finally(() => {
                setProcessing(false);
            });
    };

    useEffect(() => setPage(params.page ? parseInt(`${params.page}`, 10) : 1), [params.page]);
    useEffect(() => loadClients(), [page, location.pathname, filters]);

    const hasFilters = useMemo(() => {
        return (filters.name && filters.name !== '') || (filters.uid && filters.uid !== '');
    }, [filters]);

    return (
        <BaseLayout title={trans('optiiva::titles.clients')}>
            {processing ? (
                <Loader />
            ) : (
                <>
                    <Card size={CardSize.SM}>
                        <div className="text-right mb-8">
                            {hasFilters && (
                                <DangerButton
                                    type={ButtonType.button}
                                    onClick={clearFilters}
                                    className="mr-4"
                                >
                                    {trans('optiiva::labels.clear-filters')}
                                </DangerButton>
                            )}
                            <Button type={ButtonType.button} onClick={filterModal.openModal}>
                                {trans('optiiva::labels.filter')}
                            </Button>

                            <SearchFilterModal
                                onSubmit={(data) => setFilters(data)}
                                modalHandler={filterModal}
                                data={filters}
                            />
                        </div>

                        {clients && clients.data.length > 0 ? (
                            <ClientsTable clients={clients.data} />
                        ) : (
                            <span>No clients could be found.</span>
                        )}
                    </Card>
                    {clients && clients?.links.length > 3 && (
                        <PaginationLinks
                            links={clients.links.map((link) => ({
                                ...link,
                                url:
                                    location.pathname === '/clients/past'
                                        ? `/clients/past?page=${link.page}`
                                        : `/clients?page=${link.page}`,
                            }))}
                        />
                    )}
                </>
            )}
        </BaseLayout>
    );
};

export default Clients;
