import React from 'react';
import { Link } from 'react-router-dom';

const ProxyLink = ({
    children,
    href,
    ...props
}: {
    children: JSX.Element;
    href: string;
}): JSX.Element => (
    <Link {...props} to={href}>
        {children}
    </Link>
);

export default ProxyLink;
