import { Card, CardSize, SpinnerInverted } from '@ollico/optiiva-ui-sdk';
import { BookingResource } from '@ollico/optiiva-ui-sdk/lib/types/resources';
import React, { useEffect, useState } from 'react';
import Api from '../../../api';
import AppointmentsTable from '../../../components/appointments/AppointmentsTable';
import { useSessionContext } from '../../../context/auth';

const TodaysAppointments = (): JSX.Element => {
    const [appointments, setAppointments] = useState<BookingResource[]>([]);
    const [processing, setProcessing] = useState(true);
    const {
        context: { token },
    } = useSessionContext();

    const loadData = () => {
        setProcessing(true);

        Api.appointments(token as string)
            .todays()
            .then((data) => {
                setAppointments(data);
            })
            .finally(() => {
                setProcessing(false);
            });
    };

    useEffect(() => loadData(), []);

    return (
        <Card heading="Appointments today" size={CardSize.SM}>
            {processing ? (
                <div className="text-center">
                    <SpinnerInverted />
                </div>
            ) : (
                <>
                    {appointments.length > 0 ? (
                        <AppointmentsTable
                            refreshPage={() => loadData()}
                            isPast={false}
                            appointments={appointments}
                        />
                    ) : (
                        <span>There are no appointments scheduled for the today</span>
                    )}
                </>
            )}
        </Card>
    );
};

export default TodaysAppointments;
