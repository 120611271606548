import { Client, PaginatedResource } from '@ollico/optiiva-ui-sdk/lib/types/resources';
import { default as SLHHClient } from '@ollico/optiiva-ui-sdk/lib/components/SLHH/types/SLHHClient.interface';
import BaseApi from '../BaseApi';

enum ClientsApiPath {
    PAGINATE = 'project/slhh/clients',
    CREATE = 'clients',
    SHOW = 'clients',
}

export default class ClientsApi extends BaseApi {
    public show(id: string): Promise<Client<SLHHClient>> {
        return this.http.get(`${ClientsApiPath.SHOW}/${id}`).then((res) => res.data);
    }

    public create(client: Client<SLHHClient>): Promise<boolean> {
        let prefs = client.communication_preferences;
        if (!Array.isArray(prefs) || prefs.length === 0) {
            prefs = ['phone'];
        }

        return this.http
            .post(ClientsApiPath.CREATE, {
                ...client,
                communication_preferences: prefs,
                source: 4,
                source_other: 'a176940c-a732-4e29-beb8-5b9442ed13f4',
            })
            .then(() => true);
    }

    public update(client: Client<SLHHClient>): Promise<Client<SLHHClient>> {
        return this.http
            .put(`clients/${client.id}`, {
                ...client,
            })
            .then((response) => response.data);
    }

    public paginate(
        page: number,
        filters: { [key: string]: string },
    ): Promise<PaginatedResource<Client<SLHHClient>>> {
        return this.http
            .get(ClientsApiPath.PAGINATE, {
                params: { per_page: 20, page, ...filters },
            })
            .then((res) => res.data);
    }
}
