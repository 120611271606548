import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useSessionContext } from '../context/auth';

const PrivateRoute = ({ component, ...rest }: RouteProps): JSX.Element | null => {
    const {
        context: { isLoggedIn },
    } = useSessionContext();
    const Component = component;

    if (!Component) {
        return null;
    }

    return (
        <Route
            {...rest}
            render={(props) =>
                isLoggedIn ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={{ pathname: '/auth/login' }} />
                )
            }
        />
    );
};

export default PrivateRoute;
