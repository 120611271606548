import {
    DataRow,
    Grid,
    GridColumn,
    Modal,
    ModalHandler,
    useSemantics,
} from '@ollico/optiiva-ui-sdk';
import { ModalSize } from '@ollico/optiiva-ui-sdk/lib/components/Modal/Modal';
import { TabList, TabPanel, TabPanels, Tabs } from '@ollico/optiiva-ui-sdk/lib/components/Tabs';
import makeTabs from '@ollico/optiiva-ui-sdk/lib/components/SLHH/ClientForm/tabs';
import React from 'react';
import VisitDataResource from '../../types/resources/VisitDataResource.interface';
import { useSessionContext } from 'src/context/auth';
import { BooleanOption } from '@ollico/optiiva-ui-sdk/lib/types/enums';
import SectionHeading from '@ollico/optiiva-ui-sdk/lib/components/SectionHeading';
import HomeVisitData from './Sheets/HomeVisitData';
import HeatingSystemData from './Sheets/HeatingSystemData';
import HotWaterData from './Sheets/HotWaterData';
import BehaviouralAdviceData from './Sheets/BehaviouralAdviceData';
import WarmHomeData from './Sheets/WarmHomeData';
import WaterSureData from './Sheets/WaterSureData';
import WhiteGoodsData from './Sheets/WhiteGoodsData';
import SmartMeterData from './Sheets/SmartMeterData';
import OnwardReferralData from './Sheets/OnwardReferralData';
import FeedbackData from './Sheets/FeedbackData';
import ClosingsData from './Sheets/ClosingsData';
import BGETData from './Sheets/BGETData';
import ResidentData from './Sheets/ResidentData';

interface SearchFilterModalProps {
    modalHandler: ModalHandler;
    data: VisitDataResource;
}

const VisitModal = (props: SearchFilterModalProps): JSX.Element => {
    const { modalHandler, data } = props;
    const { trans } = useSemantics();
    const { context } = useSessionContext();

    const tabs = (makeTabs(
        trans,
        false,
        context.user?.organisation?.is_bget === BooleanOption.YES,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    )[0] as any).children;

    return (
        <Modal
            size={ModalSize.MEDIUM}
            heading={`Appointment: ${data.booking.date_time_readable}`}
            open={modalHandler.isModalOpen}
            onClose={modalHandler.closeModal}
        >
            <div className="flex flex-col h-full">
                <Tabs onChange={() => null} tabs={tabs}>
                    <Grid size="lg">
                        <GridColumn size="lg" width="w-64 border-r-3 border-gray-light flex-none">
                            <TabList />
                        </GridColumn>
                        <GridColumn size="lg" width="flex-grow">
                            <TabPanels>
                                <>
                                    <TabPanel identifier="project.project">
                                        <SectionHeading
                                            className="pb-8"
                                            heading={trans('project:slhh::headings.details')}
                                            subHeading={trans('project:slhh::headings.project')}
                                        />

                                        <DataRow
                                            label={trans('project:slhh::labels.borough')}
                                            value={
                                                data.visit_data.borough_readable ||
                                                trans('optiiva::misc.empty-line')
                                            }
                                        />

                                        <DataRow
                                            label={trans('project:slhh::labels.is-bget')}
                                            value={
                                                data.visit_data.is_bget_readable ||
                                                trans('optiiva::misc.empty-line')
                                            }
                                        />

                                        <DataRow
                                            label={trans('project:slhh::labels.how-did-you-hear')}
                                            value={
                                                <ul className="list-disc list-inside pl-2">
                                                    {!data.visit_data.how_did_you_hear_readable ||
                                                    data.visit_data.how_did_you_hear_readable
                                                        .length === 0
                                                        ? '-'
                                                        : ''}
                                                    {data.visit_data.how_did_you_hear_readable?.map(
                                                        (line) => (
                                                            <li key={`hdy-${line}`}>{line}</li>
                                                        ),
                                                    )}
                                                </ul>
                                            }
                                        />
                                    </TabPanel>

                                    <TabPanel identifier="project.home-visit">
                                        <HomeVisitData
                                            data={{ home_visit: data.visit_data.home_visit || {} }}
                                        />
                                    </TabPanel>

                                    <TabPanel identifier="project.heating-system">
                                        <HeatingSystemData
                                            data={{ heating: data.visit_data.heating || {} }}
                                        />
                                    </TabPanel>

                                    <TabPanel identifier="project.hot-water">
                                        <HotWaterData
                                            data={{ hot_water: data.visit_data.hot_water || {} }}
                                        />
                                    </TabPanel>

                                    <TabPanel identifier="project.behavioural-advice">
                                        <BehaviouralAdviceData
                                            data={{
                                                behavioural_advice:
                                                    data.visit_data.behavioural_advice || {},
                                            }}
                                        />
                                    </TabPanel>

                                    <TabPanel identifier="project.warm-home">
                                        <WarmHomeData
                                            data={{
                                                warm_home: data.visit_data.warm_home || {},
                                            }}
                                        />
                                    </TabPanel>

                                    <TabPanel identifier="project.watersure">
                                        <WaterSureData
                                            data={{
                                                watersure: data.visit_data.watersure || {},
                                            }}
                                        />
                                    </TabPanel>

                                    <TabPanel identifier="project.white-goods">
                                        <WhiteGoodsData
                                            data={{
                                                white_goods: data.visit_data.white_goods || {},
                                            }}
                                        />
                                    </TabPanel>

                                    <TabPanel identifier="project.smart-meter">
                                        <SmartMeterData
                                            data={{
                                                smart_meter: data.visit_data.smart_meter || {},
                                            }}
                                        />
                                    </TabPanel>

                                    <TabPanel identifier="project.onward-referral">
                                        <OnwardReferralData
                                            data={{
                                                onward_referral:
                                                    data.visit_data.onward_referral || {},
                                            }}
                                        />
                                    </TabPanel>

                                    <TabPanel identifier="project.feedback">
                                        <FeedbackData
                                            data={{
                                                feedback: data.visit_data.feedback || {},
                                            }}
                                        />
                                    </TabPanel>

                                    <TabPanel identifier="project.closings">
                                        <ClosingsData
                                            data={{
                                                closings: data.visit_data.closings || {},
                                            }}
                                        />
                                    </TabPanel>

                                    {context.user?.organisation?.is_bget === BooleanOption.YES && (
                                        <TabPanel identifier="project.bget">
                                            <BGETData
                                                data={{
                                                    bget: data.visit_data.bget || {},
                                                }}
                                            />
                                        </TabPanel>
                                    )}

                                    <TabPanel identifier="project.resident">
                                        <ResidentData
                                            data={{
                                                resident: data.visit_data.resident || {},
                                            }}
                                        />
                                    </TabPanel>
                                </>
                            </TabPanels>
                        </GridColumn>
                    </Grid>
                </Tabs>
            </div>
        </Modal>
    );
};

export default VisitModal;
