import { useSemantics } from '@ollico/optiiva-ui-sdk';
import { IconIndex } from '@ollico/optiiva-ui-sdk/lib/components/Icon';
import { StatBar } from '@ollico/optiiva-ui-sdk/lib/components/Stats';
import React, { useEffect, useState } from 'react';
import { BaseLayout } from '../../components/layout';
import TodaysAppointments from './components/TodaysAppointments';
import ThisWeeksAppointments from './components/ThisWeeksAppointments';
import Api from '../../api';
import { useSessionContext } from '../../context/auth';

const defaultStats = (
    trans: (key: string) => string,
    today: number,
    week: number,
    appointments: number,
    clients: number,
    loading: boolean,
) => [
    {
        label: trans('project:slhh::labels.num-appointments-today'),
        icon: IconIndex.ACTIVITY,
        number: today,
        loading,
    },
    {
        label: trans('project:slhh::labels.num-appointments-week'),
        icon: IconIndex.ACTIVITY,
        number: week,
        loading,
    },
    {
        label: trans('project:slhh::labels.total-appointments'),
        icon: IconIndex.CLIENTS,
        number: appointments,
        loading,
    },
    {
        label: trans('project:slhh::labels.total-clients'),
        icon: IconIndex.CLIENTS,
        number: clients,
        loading,
    },
];

const Dashboard = (): JSX.Element => {
    const { trans } = useSemantics();
    const { context } = useSessionContext();
    const [stats, setStats] = useState(defaultStats(trans, 0, 0, 0, 0, true));

    useEffect(() => {
        Api.appointments(context.token as string)
            .stats()
            .then((data) => {
                setStats(
                    defaultStats(
                        trans,
                        data.appointments_today,
                        data.appointments_week,
                        data.appointments_total,
                        data.clients_total,
                        false,
                    ),
                );
            });
    }, []);

    return (
        <BaseLayout title={trans('optiiva::titles.dashboard')}>
            <StatBar stats={stats} />

            <div className="my-8">
                <TodaysAppointments />
            </div>

            <div className="my-8">
                <ThisWeeksAppointments />
            </div>
        </BaseLayout>
    );
};

export default Dashboard;
