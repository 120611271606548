import { DataRow, useSemantics } from '@ollico/optiiva-ui-sdk';
import SectionHeading from '@ollico/optiiva-ui-sdk/lib/components/SectionHeading';
import { SLHHClientExtended } from '../../../types/resources/VisitDataResource.interface';
import React from 'react';

const HomeVisitData = ({ data }: { data: Pick<SLHHClientExtended, 'home_visit'> }): JSX.Element => {
    const { trans } = useSemantics();

    return (
        <>
            <SectionHeading
                className="pb-8"
                heading={trans('project:slhh::headings.home-visit')}
                subHeading={trans('project:slhh::headings.details')}
            />

            <DataRow
                horizontal
                squash
                label={trans('project:slhh::labels.financial-management')}
                value={
                    data.home_visit.financial_management_readable ||
                    trans('optiiva::misc.empty-line')
                }
            />

            <DataRow
                horizontal
                squash
                label={trans('project:slhh::labels.num-walls')}
                value={data.home_visit.num_walls || trans('optiiva::misc.empty-line')}
            />

            <DataRow
                horizontal
                squash
                label={trans('project:slhh::labels.main-walls')}
                value={data.home_visit.main_walls_readable || trans('optiiva::misc.empty-line')}
            />

            <DataRow
                horizontal
                squash
                label={trans('project:slhh::labels.insulation')}
                value={data.home_visit.insulation_readable || trans('optiiva::misc.empty-line')}
            />

            <DataRow
                horizontal
                squash
                label={trans('project:slhh::labels.wall-thickness')}
                value={data.home_visit.wall_thickness || trans('optiiva::misc.empty-line')}
            />

            <DataRow
                horizontal
                squash
                label={trans('project:slhh::labels.roof-type')}
                value={data.home_visit.roof_type_readable || trans('optiiva::misc.empty-line')}
            />

            <DataRow
                horizontal
                squash
                label={trans('project:slhh::labels.roof-access')}
                value={data.home_visit.roof_access_readable || trans('optiiva::misc.empty-line')}
            />

            <DataRow
                horizontal
                squash
                label={trans('project:slhh::labels.roof-insulation')}
                value={data.home_visit.roof_insulation || trans('optiiva::misc.empty-line')}
            />

            <DataRow
                horizontal
                squash
                label={trans('project:slhh::labels.roof-insulation')}
                value={
                    <>
                        <div>
                            {data.home_visit.floors_readable || trans('optiiva::misc.empty-line')}
                        </div>
                        {data.home_visit.floors_other}
                    </>
                }
            />

            <DataRow
                horizontal
                squash
                label={trans('project:slhh::labels.floors-insulation')}
                value={
                    data.home_visit.floors_insulation_readable || trans('optiiva::misc.empty-line')
                }
            />
        </>
    );
};

export default HomeVisitData;
