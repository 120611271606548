import localForage from 'localforage';

const storageInstance = localForage.createInstance({
    name: 'slhh',
    version: 1.0,
    description: 'Storage instance for the SLHH app',
});

const storeKeys = {
    AUTH_TOKEN: 'auth_token',
    SEMANTICS: 'semantics',
    USER: 'user',
};

export { storeKeys };

export default storageInstance;
