import { EmptyState, useFlashMessages } from '@ollico/optiiva-ui-sdk';
import { explodeFlatErrors, FormHelpers } from '@ollico/optiiva-ui-sdk/lib/components/FormProvider';
import { ClientForm } from '@ollico/optiiva-ui-sdk/lib/components/SLHH';
import SLHHClient from '@ollico/optiiva-ui-sdk/lib/components/SLHH/types/SLHHClient.interface';
import { BooleanOption } from '@ollico/optiiva-ui-sdk/lib/types/enums';
import { ErrorStack } from '@ollico/optiiva-ui-sdk/lib/types/misc';
import { Client } from '@ollico/optiiva-ui-sdk/lib/types/resources';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Api from '../../api';
import { BaseLayout } from '../../components/layout';
import Loader from '../../components/misc/Loader';
import { useSessionContext } from '../../context/auth';
import { formatClient } from '../appointments/AppointmentIntervention';

interface TParams {
    clientId: string;
}

const EditClient = ({ match }: RouteComponentProps<TParams>): JSX.Element => {
    const { context } = useSessionContext();
    const { flashError, flashSuccess } = useFlashMessages();
    const [client, setClient] = useState<Client<SLHHClient>>();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        Api.clients(context.token as string)
            .show(match.params.clientId)
            .then((data) => setClient(formatClient(data)))
            .finally(() => setLoading(false));
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }, []);

    const onSubmit = (data: Client<SLHHClient>, helpers: FormHelpers<Client<SLHHClient>>) => {
        return new Promise((resolve, reject) => {
            Api.clients(context.token as string)
                .update(data)
                .then(() => {
                    flashSuccess('Client updated');
                    resolve(null);
                })
                .catch((error: { response: { data: { message: string; errors: ErrorStack } } }) => {
                    flashError(error.response.data.message);
                    helpers.setErrors(explodeFlatErrors(error.response.data.errors));
                    reject(error);
                });
        });
    };

    return (
        <BaseLayout title="Edit client">
            {loading ? (
                <Loader />
            ) : (
                <>
                    {client ? (
                        <ClientForm
                            isBget={context.user?.organisation?.is_bget === BooleanOption.YES}
                            onlyProject
                            errors={{}}
                            onSubmit={onSubmit}
                            client={client}
                        />
                    ) : (
                        <EmptyState message="The client requested could not be found." />
                    )}
                </>
            )}
        </BaseLayout>
    );
};

export default EditClient;
