import { AppLayout, useSemantics } from '@ollico/optiiva-ui-sdk';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSessionContext } from '../../context/auth';
import { UserResource } from '../../types/resources';

enum MenuKey {
    DASHBOARD = 'dashboard',
    APPOINTMENTS = 'appointments',
    CLIENTS = 'clients',
    CREATE_CLIENT = 'clients.add',
}

interface BaseLayoutProps {
    children: React.ReactNode;
    title?: string;
}

const BaseLayout = ({ children, title }: BaseLayoutProps): JSX.Element => {
    const { trans } = useSemantics();
    const location = useLocation();
    const {
        context: { user },
    } = useSessionContext();

    const menu = [
        {
            key: MenuKey.DASHBOARD,
            label: trans('optiiva::menu.dashboard'),
            icon: null,
            url: '/',
            active: location.pathname.search(/^\/$/) > -1,
        },
        {
            key: MenuKey.APPOINTMENTS,
            label: trans('optiiva::menu.appointments'),
            icon: null,
            url: '/appointments',
            active: location.pathname.search(/^\/appointments\/|^\/appointments$/) > -1,
        },
        {
            key: MenuKey.CLIENTS,
            label: trans('optiiva::menu.clients'),
            icon: null,
            url: '/clients',
            active: location.pathname.search(/^\/clients$/) > -1,
        },
        {
            key: MenuKey.CREATE_CLIENT,
            label: trans('optiiva::menu.add-client'),
            icon: null,
            url: '/clients/add',
            active: location.pathname.search(/^\/clients\/add$/) > -1,
        },
    ];

    return (
        <AppLayout
            menu={menu}
            title={title}
            userMenu={[
                {
                    label: trans('optiiva::labels.log-out'),
                    href: '/auth/logout',
                    inertia: false,
                },
            ]}
            user={user as UserResource}
        >
            <div className="pb-16">{children}</div>
        </AppLayout>
    );
};

export default BaseLayout;
