import {
    Button,
    ButtonType,
    Card,
    CardSize,
    DangerButton,
    PaginationLinks,
    route,
    TabNav,
    useModal,
    useSemantics,
} from '@ollico/optiiva-ui-sdk';
import { BookingResource, PaginatedResource } from '@ollico/optiiva-ui-sdk/lib/types/resources';
import QueryString from 'query-string';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import SearchFilterModal, { SearchFilterData } from 'src/components/Modal/SearchFilterModal';
import Api from '../../api';
import AppointmentsTable from '../../components/appointments/AppointmentsTable';
import { BaseLayout } from '../../components/layout';
import Loader from '../../components/misc/Loader';
import { useSessionContext } from '../../context/auth';

interface AppointmentsProps {
    location: {
        search: string;
        pathname: string;
    };
}

const defaultFilters = {
    uid: null,
    name: null,
    status: null,
};

const Appointments = ({ location }: AppointmentsProps): JSX.Element => {
    const filterModal = useModal();
    const { trans } = useSemantics();
    const params = QueryString.parse(location.search);
    const { context } = useSessionContext();
    const [filters, setFilters] = useState<SearchFilterData>(defaultFilters);
    const [appointments, setAppointments] = useState<PaginatedResource<BookingResource>>();
    const [processing, setProcessing] = useState(true);
    const [page, setPage] = useState(1);
    const clearFilters = useCallback(() => {
        setFilters(defaultFilters);
    }, []);

    const loadAppointments = () => {
        setProcessing(true);

        const filtered: { [key: string]: string } = {};
        const keys = Object.keys(filters);

        for (let index = 0; index < keys.length; index++) {
            const filter = filters[keys[index]];
            if (filter) {
                filtered[keys[index]] = filter;
            }
        }

        Api.appointments(context.token as string)
            .paginate(page, {
                ...filtered,
                when: location.pathname === '/appointments/past' ? 'past' : 'upcoming',
            })
            .then((data) => setAppointments(data))
            .finally(() => setProcessing(false));
    };

    useEffect(() => setPage(params.page ? parseInt(`${params.page}`, 10) : 1), [params.page]);
    useEffect(() => loadAppointments(), [page, location.pathname, filters]);

    const hasFilters = useMemo(() => {
        return (
            (filters.name && filters.name !== '') ||
            (filters.status && filters.status !== '') ||
            (filters.uid && filters.uid !== '')
        );
    }, [filters]);

    const tabLinks = useMemo(
        () => [
            {
                label: trans('optiiva::menu.upcoming'),
                href: '/appointments',
                active: location.pathname === '/appointments',
            },
            {
                label: trans('optiiva::menu.past'),
                href: '/appointments/past',
                active: location.pathname === '/appointments/past',
            },
        ],
        [location.pathname],
    );

    return (
        <BaseLayout title={trans('optiiva::titles.appointments')}>
            {processing ? (
                <Loader />
            ) : (
                <>
                    <Card size={CardSize.SM}>
                        <div className="flex justify-between mb-8">
                            <div>
                                <TabNav links={tabLinks} />
                            </div>
                            <div className="text-right">
                                {hasFilters && (
                                    <DangerButton
                                        type={ButtonType.button}
                                        onClick={clearFilters}
                                        className="mr-4"
                                    >
                                        {trans('optiiva::labels.clear-filters')}
                                    </DangerButton>
                                )}
                                <Button type={ButtonType.button} onClick={filterModal.openModal}>
                                    {trans('optiiva::labels.filter')}
                                </Button>
                                <SearchFilterModal
                                    onSubmit={(data) => setFilters(data)}
                                    modalHandler={filterModal}
                                    data={filters}
                                />
                            </div>
                        </div>
                        {appointments && appointments?.data.length > 0 ? (
                            <AppointmentsTable
                                refreshPage={() => loadAppointments()}
                                isPast={location.pathname === '/appointments/past'}
                                appointments={appointments.data}
                            />
                        ) : (
                            <span>No appointments could be found.</span>
                        )}
                    </Card>
                    {appointments && appointments?.links.length > 3 && (
                        <PaginationLinks
                            links={appointments.links.map((link) => ({
                                ...link,
                                url:
                                    location.pathname === '/appointments/past'
                                        ? `/appointments/past?page=${link.page}`
                                        : `/appointments?page=${link.page}`,
                            }))}
                        />
                    )}
                </>
            )}
        </BaseLayout>
    );
};

export default Appointments;
