import { DataRow, useSemantics } from '@ollico/optiiva-ui-sdk';
import SectionHeading from '@ollico/optiiva-ui-sdk/lib/components/SectionHeading';
import React from 'react';
import { SLHHClientExtended } from '../../../types/resources/VisitDataResource.interface';
import RowWithOther from './RowWithOther';

const BehaviouralAdviceData = ({
    data,
}: {
    data: Pick<SLHHClientExtended, 'behavioural_advice'>;
}): JSX.Element => {
    const { trans } = useSemantics();

    return (
        <>
            <SectionHeading
                className="pb-8"
                heading={trans('project:slhh::headings.behavioural-advice')}
                subHeading={trans('project:slhh::headings.details')}
            />

            <DataRow
                squash
                label={trans('project:slhh::labels.behavioural-advice-given')}
                value={
                    <RowWithOther
                        row={
                            data.behavioural_advice.behavioural_advice_given_readable
                                ? data.behavioural_advice.behavioural_advice_given_readable.join(
                                      ', ',
                                  )
                                : trans('optiiva::misc.empty-line')
                        }
                        other={data.behavioural_advice.behavioural_advice_given_other}
                    />
                }
            />

            <DataRow
                squash
                label={trans('project:slhh::labels.smoke-alarm-elevation')}
                value={
                    <RowWithOther
                        row={
                            data.behavioural_advice.smoke_alarm_elevation_readable ||
                            trans('optiiva::misc.empty-line')
                        }
                        other={data.behavioural_advice.smoke_alarm_elevation_other}
                    />
                }
            />

            <DataRow
                squash
                label={trans('project:slhh::labels.lbr-referral')}
                value={
                    <RowWithOther
                        row={
                            data.behavioural_advice.lbr_referral_readable ||
                            trans('optiiva::misc.empty-line')
                        }
                        other={data.behavioural_advice.lbr_referral_other}
                    />
                }
            />

            <DataRow
                squash
                label={trans('project:slhh::labels.carbon-monoxide-alarm')}
                value={
                    <RowWithOther
                        row={
                            data.behavioural_advice.carbon_monoxide_alarm_readable ||
                            trans('optiiva::misc.empty-line')
                        }
                        other={data.behavioural_advice.carbon_monoxide_alarm_other}
                    />
                }
            />

            <DataRow
                squash
                label={trans('project:slhh::labels.behavioural-advice-price-comparison-given')}
                value={
                    data.behavioural_advice.price_comparison_advice_given_readable
                        ? data.behavioural_advice.price_comparison_advice_given_readable?.join(', ')
                        : trans('optiiva::misc.empty-line')
                }
            />

            <DataRow
                squash
                label={trans('project:slhh::labels.price-comparison-advice-taken')}
                value={
                    <RowWithOther
                        row={
                            data.behavioural_advice.price_comparison_advice_taken_readable ||
                            trans('optiiva::misc.empty-line')
                        }
                        other={data.behavioural_advice.price_comparison_advice_taken_other}
                    />
                }
            />

            <DataRow
                squash
                label={trans('project:slhh::labels.supplier-and-tariff-identified')}
                value={
                    data.behavioural_advice.supplier_and_tariff_identified ||
                    trans('optiiva::misc.empty-line')
                }
            />

            <DataRow
                squash
                label={trans('project:slhh::labels.savings-from-switch')}
                value={
                    data.behavioural_advice.savings_from_switch || trans('optiiva::misc.empty-line')
                }
            />
        </>
    );
};

export default BehaviouralAdviceData;
