import { DataRow, useSemantics } from '@ollico/optiiva-ui-sdk';
import SectionHeading from '@ollico/optiiva-ui-sdk/lib/components/SectionHeading';
import React from 'react';
import { SLHHClientExtended } from '../../../types/resources/VisitDataResource.interface';
import RowWithOther from './RowWithOther';

const numBoxes = {
    num_in_household_5: 'num-in-household-5',
    num_in_household_6: 'num-in-household-6',
    num_in_household_12: 'num-in-household-12',
    num_in_household_18: 'num-in-household-18',
    num_in_household_25: 'num-in-household-25',
    num_in_household_35: 'num-in-household-35',
    num_in_household_45: 'num-in-household-45',
    num_in_household_55: 'num-in-household-55',
    num_in_household_65: 'num-in-household-65',
    num_in_household_75: 'num-in-household-75',
};

const ResidentData = ({ data }: { data: Pick<SLHHClientExtended, 'resident'> }): JSX.Element => {
    const { trans } = useSemantics();

    return (
        <>
            <SectionHeading
                className="pb-8"
                heading={trans('project:slhh::headings.resident')}
                subHeading={trans('project:slhh::headings.slhh')}
            />

            <DataRow
                squash
                label={trans('project:slhh::labels.mental-health-issues')}
                value={
                    <RowWithOther
                        row={
                            data.resident.mental_health_issues_readable
                                ? data.resident.mental_health_issues_readable.join(', ')
                                : trans('optiiva::misc.empty-line')
                        }
                        other={data.resident.mental_health_issues_other}
                    />
                }
            />

            <DataRow
                squash
                label="Oldest resident's DoB"
                value={data.resident.oldest_dob || trans('optiiva::misc.empty-line')}
            />

            <DataRow
                squash
                label={trans('project:slhh::labels.autistic-spectrum')}
                value={
                    <RowWithOther
                        row={
                            data.resident.autistic_spectrum_readable ||
                            trans('optiiva::misc.empty-line')
                        }
                        other={data.resident.autistic_spectrum_other}
                    />
                }
            />

            <DataRow
                squash
                label={trans('project:slhh::labels.num-in-household')}
                value={data.resident.num_in_household || trans('optiiva::misc.empty-line')}
            />

            {Object.keys(numBoxes).map((key) => (
                <DataRow
                    key={`num-${key}`}
                    squash
                    label={trans(`project:slhh::labels.${numBoxes[key]}`)}
                    value={data.resident[key] || trans('optiiva::misc.empty-line')}
                />
            ))}
        </>
    );
};

export default ResidentData;
