import { DataRow, useSemantics } from '@ollico/optiiva-ui-sdk';
import SectionHeading from '@ollico/optiiva-ui-sdk/lib/components/SectionHeading';
import { SLHHClientExtended } from '../../../types/resources/VisitDataResource.interface';
import React from 'react';
import RowWithOther from './RowWithOther';

const HeatingSystemData = ({
    data,
}: {
    data: Pick<SLHHClientExtended, 'heating'>;
}): JSX.Element => {
    const { trans } = useSemantics();

    return (
        <>
            <SectionHeading
                className="pb-8"
                heading={trans('project:slhh::headings.heating-system')}
                subHeading={trans('project:slhh::headings.details')}
            />

            <DataRow
                horizontal
                squash
                label={trans('project:slhh::labels.heating-type')}
                value={data.heating.heating_type_readable || trans('optiiva::misc.empty-line')}
            />

            <DataRow
                horizontal
                squash
                label={trans('project:slhh::labels.heating-system-model')}
                value={data.heating.heating_system_model || trans('optiiva::misc.empty-line')}
            />

            <DataRow
                horizontal
                squash
                label={trans('project:slhh::labels.heating-system-working')}
                value={
                    <RowWithOther
                        row={
                            data.heating.heating_system_working_readable ||
                            trans('optiiva::misc.empty-line')
                        }
                        other={data.heating.heating_system_working_other}
                    />
                }
            />

            <DataRow
                horizontal
                squash
                label={trans('project:slhh::labels.heating-controls')}
                value={data.heating.heating_controls_readable || trans('optiiva::misc.empty-line')}
            />
        </>
    );
};

export default HeatingSystemData;
