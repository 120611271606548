import { DataRow, useSemantics } from '@ollico/optiiva-ui-sdk';
import SectionHeading from '@ollico/optiiva-ui-sdk/lib/components/SectionHeading';
import React from 'react';
import { SLHHClientExtended } from '../../../types/resources/VisitDataResource.interface';
import RowWithOther from './RowWithOther';

const BGETData = ({ data }: { data: Pick<SLHHClientExtended, 'bget'> }): JSX.Element => {
    const { trans } = useSemantics();

    return data.bget ? (
        <>
            <SectionHeading
                className="pb-8"
                heading={trans('project:slhh::headings.bget')}
                subHeading={trans('project:slhh::headings.details')}
            />

            <DataRow
                squash
                label={trans('project:slhh::labels.income-less-than')}
                value={data.bget.income_less_than_readable || trans('optiiva::misc.empty-line')}
            />

            <DataRow
                squash
                label={trans('project:slhh::labels.income-reduced')}
                value={data.bget.income_reduced_readable || trans('optiiva::misc.empty-line')}
            />

            <DataRow
                squash
                label={trans('project:slhh::labels.energy-improvements')}
                value={
                    <RowWithOther
                        row={
                            data.bget.energy_improvements_readable
                                ? data.bget.energy_improvements_readable.join(', ')
                                : trans('optiiva::misc.empty-line')
                        }
                        other={data.bget.energy_improvements_other}
                    />
                }
            />

            <DataRow
                squash
                label={trans('project:slhh::labels.energy-efficiency-referral')}
                value={
                    <RowWithOther
                        row={
                            data.bget.energy_efficiency_referral_readable
                                ? data.bget.energy_efficiency_referral_readable.join(', ')
                                : trans('optiiva::misc.empty-line')
                        }
                        other={data.bget.energy_efficiency_referral_other}
                    />
                }
            />

            <DataRow
                squash
                label={trans('project:slhh::labels.energy-efficiency-signposts')}
                value={
                    <RowWithOther
                        row={
                            data.bget.energy_efficiency_signposts_readable
                                ? data.bget.energy_efficiency_signposts_readable.join(', ')
                                : trans('optiiva::misc.empty-line')
                        }
                        other={data.bget.energy_efficiency_signposts_other}
                    />
                }
            />

            <DataRow
                squash
                label={trans('project:slhh::labels.budget-plan-created')}
                value={data.bget.budget_plan_created_readable || trans('optiiva::misc.empty-line')}
            />

            <DataRow
                squash
                label={trans('project:slhh::labels.budget-for-energy')}
                value={data.bget.budget_for_energy || trans('optiiva::misc.empty-line')}
            />

            <DataRow
                squash
                label={trans('project:slhh::labels.maximisation-check-performed')}
                value={
                    data.bget.maximisation_check_performed_readable ||
                    trans('optiiva::misc.empty-line')
                }
            />

            <DataRow
                squash
                label={trans('project:slhh::labels.referred-to-another')}
                value={
                    <RowWithOther
                        row={
                            data.bget.referred_to_another_readable ||
                            trans('optiiva::misc.empty-line')
                        }
                        other={data.bget.referred_to_another_reason}
                    />
                }
            />

            <DataRow
                squash
                label={trans('project:slhh::labels.value-of-debt-managed')}
                value={data.bget.value_of_debt_managed || trans('optiiva::misc.empty-line')}
            />

            <DataRow
                squash
                label={trans('project:slhh::labels.value-of-debt-written-off')}
                value={data.bget.value_of_debt_written_off || trans('optiiva::misc.empty-line')}
            />

            <DataRow
                squash
                label={trans('project:slhh::labels.advocate-fuel-supplier')}
                value={
                    data.bget.advocate_fuel_supplier_readable || trans('optiiva::misc.empty-line')
                }
            />

            <DataRow
                squash
                label={trans('project:slhh::labels.action-taken')}
                value={data.bget.action_taken || trans('optiiva::misc.empty-line')}
            />

            <DataRow
                squash
                label={trans('project:slhh::labels.what-did-you-advocate')}
                value={
                    <RowWithOther
                        row={
                            data.bget.what_did_you_advocate_readable
                                ? data.bget.what_did_you_advocate_readable.join(', ')
                                : trans('optiiva::misc.empty-line')
                        }
                        other={data.bget.what_did_you_advocate_other}
                    />
                }
            />

            <DataRow
                squash
                label={trans('project:slhh::labels.grant-application-submitted')}
                value={
                    <RowWithOther
                        row={
                            data.bget.grant_application_submitted_readable
                                ? data.bget.grant_application_submitted_readable.join(', ')
                                : trans('optiiva::misc.empty-line')
                        }
                        other={
                            <>
                                <span className="block">
                                    {data.bget.grant_application_submitted_value}
                                </span>
                                <span className="block">
                                    {data.bget.grant_application_submitted_other}
                                </span>
                            </>
                        }
                    />
                }
            />

            <DataRow
                squash
                label={trans('project:slhh::labels.grant-application-awarded')}
                value={
                    data.bget.grant_application_awarded_readable
                        ? data.bget.grant_application_awarded_readable.join(', ')
                        : trans('optiiva::misc.empty-line')
                }
            />
        </>
    ) : (
        <span />
    );
};

export default BGETData;
