import {
    AuthLayout,
    Card,
    FlashMessagesProvider,
    SemanticsProvider,
    SpinnerInverted,
} from '@ollico/optiiva-ui-sdk';
import * as OptiivaLink from '@ollico/optiiva-ui-sdk/lib/components/Link';
import React, { useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import ProxyLink from './components/link/ProxyLink';
import { useSessionContext } from './context/auth';
import { PrivateRoute } from './router';
import './sass/app.scss';
import AppointmentIntervention from './screens/appointments/AppointmentIntervention';
import Appointments from './screens/appointments/Appointments';
import EditAppointment from './screens/appointments/EditAppointment';
import AuthCallbackHandler from './screens/auth/AuthCallbackHandler';
import AddClient from './screens/clients/AddClient';
import EditClient from './screens/clients/EditClient';
import Clients from './screens/clients/Clients';
import Dashboard from './screens/dashboard/Dashboard';
import PageNotFound from './screens/errors/PageNotFound';
import storageInstance, { storeKeys } from './store';

OptiivaLink.setGlobalLinkEl(ProxyLink);

function Main(): JSX.Element {
    const location = useLocation();
    const {
        context: { appReady },
        initializeApp,
        semantics,
    } = useSessionContext();

    useEffect(() => initializeApp(location.pathname.search(/\/auth\/[^\0]+$/) > -1), []);

    return (
        <>
            {appReady ? (
                <SemanticsProvider lang={semantics.lang}>
                    <FlashMessagesProvider>
                        <Switch>
                            <Route
                                path="/auth/login"
                                component={() => {
                                    window.location.href = `${process.env.REACT_APP_LOGIN_URL}`;
                                    return null;
                                }}
                            />
                            <Route
                                path="/auth/logout"
                                component={() => {
                                    storageInstance.removeItem(storeKeys.AUTH_TOKEN);
                                    storageInstance.removeItem(storeKeys.USER);
                                    window.location.href = `${process.env.REACT_APP_LOGIN_URL}`;
                                    return null;
                                }}
                            />
                            <Route path="/auth/callback/:token" component={AuthCallbackHandler} />
                            <PrivateRoute exact path="/" component={Dashboard} />
                            <PrivateRoute exact path="/appointments" component={Appointments} />
                            <PrivateRoute
                                exact
                                path="/appointments/past"
                                component={Appointments}
                            />
                            <PrivateRoute
                                exact
                                path="/appointments/edit/:bookingId"
                                component={EditAppointment}
                            />
                            <PrivateRoute
                                exact
                                path="/appointments/:clientId/intervention/:bookingId"
                                component={AppointmentIntervention}
                            />
                            <PrivateRoute path="/clients/add" component={AddClient} />
                            <PrivateRoute path="/clients/:clientId/edit" component={EditClient} />
                            <PrivateRoute path="/clients" component={Clients} />
                            <Route path="*" component={PageNotFound} />
                        </Switch>
                    </FlashMessagesProvider>
                </SemanticsProvider>
            ) : (
                <AuthLayout>
                    <Card>
                        <div className="flex items-center">
                            <div>
                                <SpinnerInverted />
                            </div>
                            <span className="pl-4 font-medium text-sm leading-none">
                                Loading config and checking session
                            </span>
                        </div>
                    </Card>
                </AuthLayout>
            )}
        </>
    );
}

export default Main;
